import * as React from 'react'
// import Navbar from '../components/navbar'
import Seo from '../components/seo'
import Video from '../components/video'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
// import PostLink from "../components/post-link"
// import promoSlika from '../images/kruzic-promo.png'
// import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// const imgStyle = `mx-auto rounded-lg shadow-2xl`

// <Link to='/'>Idi na naslovnu stranicu...</Link>.

library.add(fas)

const IndexPage = ({
  data: {
    allMdx: { edges },
  }, // this prop will be injected by the GraphQL query below.
}) => {
  // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
  // .map(edge => <p key={edge.node.id}>{edge.node} <p/>)
  return (
    <>
      <Seo title='Naslovnica' />
      <Layout>
        <StaticImage
          src='../images/marinko-zahvala-2a.jpg'
          alt='Marinko Žic'
          placeholder='blurred'
          quality='100'
          as='div'
          className='my-8 wide'
        />
        <section className='my-8'>
          <p className='text-xl mb-10'>
            Drage mje&scaron;tanke i mje&scaron;tani Punta i Stare Ba&scaron;ke,
          </p>
          <p>
            nakon 8 godina na čelu na&scaron;e Općine, vrijeme je za rastanak
            jer ste tako vi htjeli i odlučili svojim glasovima na jučer održanom
            2. krugu izbora.
            <br />
            Iza sebe ostavljam zavr&scaron;ene projekte, kao i one u dobroj fazi
            pripreme &scaron;to će sve olak&scaron;ati novom načelniku prvih 100
            dana mandata.
            <br />
            Kao &scaron;to sam rekao u izjavi za medije, odgovornost je na meni
            i odlukama da se uvede red u općinske financije kao i stav da
            općinska uprava mora prema svima biti jednaka.
          </p>
          <p>
            Čestitam novom načelniku Danijelu Strčiću i prelazim na opozicijsku
            stranu, nastavljajući svoj doprinos razvoju lokalne zajednice kroz
            Općinsko vijeće u kojem smo osvojili većinsko povjerenje birača i
            pet vijećnika.&nbsp;
          </p>
          <p>Va&scaron; Marinko Žic</p>
        </section>
        <hr />
        {/* <p className='h-4'></p>

        <Video
          videoSrcURL='https://youtube.com/embed/ffJf1jNMb4w'
          videoTitle='Poziv na izbore'
        /> */}
        <section className='objave my-8'>
          <div className='grid grid-cols-objavefit gap-8 my-8'>
            {edges.map(({ node }) => {
              const { title, slug, date, promoSlika } = node.frontmatter
              return (
                <article key={node.id} className='max-w-3xl'>
                  {promoSlika ? (
                    <a href={slug}>
                      <GatsbyImage
                        image={promoSlika.childImageSharp.gatsbyImageData}
                      />
                    </a>
                  ) : (
                    <StaticImage
                      src='../images/marinko-promo-1.jpg'
                      alt='Marinko Žic - načelnik prijatelj'
                      placeholder='blurred'
                      quality='100'
                      as='div'
                      className='mb-8 wide'
                    />
                  )}

                  <h3 key={slug}>
                    <a href={slug}>{title}</a>
                  </h3>
                  <p className='text-sm text-siva'>{date}</p>

                  <p className=''>{node.excerpt}</p>
                  <p className='text-right mt-2'>
                    <a href={slug} className='no-underline py-2 text-siva '>
                      Pročitaj ostatak
                      <FontAwesomeIcon icon='plus-circle' className='ml-2' />
                    </a>
                  </p>
                </article>
              )
            })}
          </div>
        </section>
        {/* <StaticImage
          src='../images/marinko-promo-1.jpg'
          alt='Marinko Žic - načelnik prijatelj'
          placeholder='blurred'
          quality='100'
          as='div'
          className='my-8 wide'
        /> */}
        {/* <StaticImage
          src='../images/marinkozic-zaokruzi1-2krug.jpg'
          alt='Marinko Žic - načelnik prijatelj, zaokruži 1'
          placeholder='blurred'
          quality='100'
          as='div'
          className='my-8 wide'
        /> */}
      </Layout>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query MyQuery {
    allMdx(
      limit: 3
      filter: { fileAbsolutePath: { regex: "/objave/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "dddd, DD. MMMM, YYYY.", locale: "hr")
            title
            slug
            promoSlika {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          body
          excerpt
        }
      }
    }
  }
`
